/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { ControlTariff } from '@/store/tariff/types';
import { getLocalStorageService } from '@/services/storage.service'

const namespace: string = 'tariff';

export default class ControlTariffRoomChatPage extends Vue {
	@State('tariff') tariff: ControlTariff | undefined;
	@Getter('getCurrentViewModel', {namespace}) getCurrentViewModel: any;
	@Getter('getSelectedModels', {namespace}) getSelectedModels: any;
	@Getter('getMessageFromChat', {namespace}) getMessageFromChat: any;
	@Getter('getControlMpdels', {namespace}) getControlMpdels: any;
	@Action('storeCameraPosition', {namespace}) storeCameraPosition: any;
	@Action('storeChatMessage', {namespace}) storeChatMessage: any;
	@Action('fetchChatMessage', {namespace}) fetchChatMessage: any;
	@Action('storeDonate', {namespace}) storeDonate: any;
	@Action('unlockSuccess', {namespace}) unlockSuccess: any;

	modelChat: any = {
		chatMessage: {
			message: ''
		}
	}
	chooseRoomRadio: string = '';

	toRoomRadio() {
		this.storeCameraPosition({
			cameraPosition: this.chooseRoomRadio
		});
	}
	toDonate(type: string) {
		if(type === 'vip') this.storeDonate({vipDonate: true})
		if(type === 'donate') this.storeDonate({donate: true})
	}
	sendChatMessage() {
		const name = getLocalStorageService('login');
		this.storeChatMessage({
			username: name,
			message: this.modelChat.chatMessage.message
		});
		this.modelChat.chatMessage.message = '';
	}
	cameraButton(event: Event & {
		target: HTMLElement
	}) {
		const {target} = event;
		console.log(target.innerText);
		this.storeCameraPosition({
			cameraPosition: target.innerText
		});
	}
	unlockCameras(duration: string, cost: number) {
		this.unlockSuccess({
			type: duration,
			price: cost
		})
	}
	created() {
		this.fetchChatMessage();
	}
}
